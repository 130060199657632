import React, { useRef, useState } from "react";
import "./DiceCheck.scss";

const DiceCheck = ({
  diceCheck,
  index,
  setLast,
  result,
  savePara,
  savedRoll,
}) => {
  console.log("look for dice check");
  const [outcome, setOutcome] = useState(
    !savedRoll.completed
      ? "NONE"
      : savedRoll.diceRoll.success >= diceCheck.dc
      ? "SUCCESS"
      : "FAIL"
  );
  const [rollMade, setRollMade] = useState(savedRoll?.completed);
  const [diceRoll, setDiceRoll] = useState(
    savedRoll?.completed ? savedRoll.diceRoll.roll : null
  );
  const ref = useRef(null);

  const checkRoll = () => {
    const roll = ref.current.value;
    if (!roll) {
      return;
    }
    if (roll != null) {
      if (roll >= diceCheck.dc) {
        setOutcome("SUCCESS");
      } else if (diceCheck.partialDc && roll >= diceCheck.partialDc) {
        setOutcome("PARTIAL");
      } else {
        setOutcome("FAIL");
      }
    }
    setRollMade(true);
    setDiceRoll(roll);
    setLast();
    const body = {
      diceRoll: {
        roll: roll,
        success:
          roll >= diceCheck.dc
            ? "SUCCESS"
            : roll >= diceCheck.partialDc
            ? "PARTIAL"
            : "FAIL",
      },
      completed: true,
      type: "diceCheck",
    };
    savePara(index, body);
  };

  return (
    <div className="dice-check" key={index}>
      <p className="check-prompt">
        {"Roll a "}
        <span>{diceCheck.skill + " "}</span>
        {diceCheck.rollType}
        {":"}
      </p>
      <p className="bonus-reminder">
        {diceCheck.isAdvantaged
          ? "Don't forget to roll with advantage!"
          : diceCheck.isBlessed
          ? "Don't forget to add your D4!"
          : ""}
      </p>
      <div className="input-container">
        <input
          type="number"
          value={diceRoll}
          placeholder="Enter your roll"
          ref={ref}
          disabled={rollMade}
        />
        <button onClick={checkRoll} disabled={rollMade}>
          Submit
        </button>
      </div>
      {rollMade && diceCheck.skill !== "Mook" && (
        <>
          {outcome === "SUCCESS" ? (
            <p className="adventure-text success">You succeeded!</p>
          ) : outcome === "FAIL" ? (
            <p className="adventure-text fail">You failed your roll!</p>
          ) : outcome === "PARTIAL" ? (
            <p className="adventure-text success">A partial success...</p>
          ) : (
            <> </>
          )}
          <p className="adventure-text">
            {outcome === "SUCCESS"
              ? diceCheck.success
              : outcome === "FAIL"
              ? diceCheck.fail
              : outcome === "PARTIAL"
              ? diceCheck.partialSuccess
              : ""}
          </p>
        </>
      )}
    </div>
  );
};

export default DiceCheck;
